body { background-color: #1c1c21 !important; }

.h-3 { height: 2.5rem; }

.fs-12 { font-size: 12px !important; }
.fs-seat-number { font-size: .7rem !important; }

.h2 { font-size: 2rem !important; }

.text-gray { color: #707070 !important; }

.text-light-gray { color: #DDDDDD !important; }

.text-a { color: #54afbc !important; }
.text-s { color: #ffb44a !important; }
.text-k { color: #fe5c36 !important; }

.bg-a { background-color: #54afbc !important; }
.bg-s { background-color: #ffb44a !important; }
.bg-k { background-color: #fe5c36 !important; }

.bg-dark { background-color: #17171b !important; }
.bg-light-dark { background-color: #1c1c21 !important; }

.shadow-none { box-shadow: none; }

.p-inherit { position: inherit; }

.footer .nav .nav-item .nav-link { color: #DDDDDD !important; }
.footer .nav .nav-item .nav-link:hover { color: #fe5c36 !important; }

.nav-link { cursor: pointer; }

.gray-logo {
    filter: grayscale(1);
}

.bottom-nav {
    position: fixed;
    bottom: 18px;
    left: 20%;
    right: 20%;
    z-index: 1;
    background-color: #17171b;
    padding: 10px;
    border-radius: 50px;
}

@media only screen and (max-width: 600px) {
    .bottom-nav {
        bottom: 10px;
        left: 3%;
        right: 3%;
    }
}

.bottom-nav-bg {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    height: 88px;
    border-radius: 0;
    background-color: #1c1c21;
    z-index: 1;
}

.nav-pills .nav-link {
    padding: 0.6rem 1rem;
    border-radius: 50px;
    background-color: transparent;
    color: #ddddde;
    font-size: 0.8rem;
}

.instagram { color: #dddddd !important; }
.instagram .instagram-hover:hover { color: #54afbc !important; }
.discord { color: #dddddd !important; }
.discord-hover:hover { color: #ffb44a !important; }
.telegram { color: #dddddd !important; }
.telegram-hover:hover { color: #fe5c36 !important; }

@media only screen and (max-width: 600px) {
    .l-15 { left: 15px; }
    .r-15 { right: 15px; }
}

.container, .container-fluid, .container-xl, .container-lg, .container-md, .container-sm {
    width: 100%;
    padding-right: 25px;
    padding-left: 25px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 1200px) {
    .container-xl, .container-lg, .container-md, .container-sm, .container {
        max-width: 1400px;
    }
}

.fuse-text {
    color: #1c1c21;
	animation:ani 0.20s infinite;
}

@keyframes ani{
	10%{
		color:#1c1c21;
		text-shadow:0px 0px 0px white,
					0px 0px 10px white,
					0px 0px 0px white,
					0px 0px 0px white,
					0px 0px 0px white,
					0px 0px 0px white,
					0px 0px 0px white;
	}
}

.page-header {
    padding: 0;
    position: relative;
    overflow: hidden;
    display: grid;
    align-items: center;
    background-size: cover;
    background-position: 50%;
}

.header-filter:after, .header-filter:before {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    display: block;
    left: 0;
    top: 0;
    content: "";
}

.purple-filter:after {
    background: rgba(101,47,142,.64);
    background: linear-gradient(90deg,#17171b calc(50vw - 510px),#204a5fd6 50%,#204a5fd6);
}

.video-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 122px;
}

.cursor-pointer {
    cursor: pointer;
}

.join-us-form-control {
    height: calc(1.5em + 1rem);
    font-size: 0.7rem !important;
    border: 1px solid #000000;
    border-radius: 0;
    color: #000;
}

.gradient-card {
    background: linear-gradient(180deg,#17171b calc(37vw - 510px),#1c1c21 78%,#1c1c21);
}

.theatre-seat {
    background-color: #2d2d2e;
    height: 26px;
    width: 26px;
    margin-bottom: 0.4rem;
    margin-left: 0.15rem;
    margin-right: 0.15rem;
    padding: 0.15rem;
    align-items: center;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.host-seat {
    background-color: #fd5b3570;
    height: 26px;
    width: 26px;
    margin-bottom: 0.4rem;
    margin-left: 0.15rem;
    margin-right: 0.15rem;
    padding: 0.15rem;
    align-items: center;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.seat-img {
    height: 21px;
    width: 21px;
    object-fit: cover;
    border-radius: 6px;
}

.swiper {
    width: 100%;
    height: 100%;
}
  
.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}
  
.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
  
.swiper {
    width: 100%;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
}
  
.swiper-slide {
    background-size: cover;
    background-position: center;
}
  
.mySwiper2 {
    height: 80%;
    width: 100%;
}
  
.mySwiper {
    height: 20%;
    box-sizing: border-box;
    padding: 10px 0;
}
  
.mySwiper .swiper-slide {
    width: 42% !important;
    height: 85px;
}
  
.mySwiper .swiper-slide-thumb-active {
    opacity: 1;
}
  
.swiper-slide img {
    display: block;
    width: 100%;
    height: 180px;
    object-fit: cover;
}
  
.msger-header {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: var(--border);
    background: #eee;
    color: #666;
}
  
.msger-chat {
    flex: 1;
    overflow-y: auto;
    padding: 10px;
    height: 81vh;
}

.msger-chat::-webkit-scrollbar {
    width: 6px;
}

.msger-chat::-webkit-scrollbar-track {
    background: #ddd;
}

.msger-chat::-webkit-scrollbar-thumb {
    background: #bdbdbd;
}

.msg {
    display: flex;
    align-items: flex-end;
    margin-bottom: 10px;
}

.msg:last-of-type {
    margin: 0;
}

.msg-img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    background: #ddd;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
}

.msg-bubble {
    max-width: 385px;
    padding: 15px;
    border-radius: 15px;
    background: #17171b;
    color: aliceblue;
}

.msg-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.msg-text {
    font-size: 13px;
}

.msg-info-name {
    margin-right: 10px;
    font-weight: 500;
    font-size: 9px;
    color: #9E9E9E;
}

.msg-info-time {
    font-size: 0.7em;
}
  
.left-msg .msg-bubble {
    border-bottom-left-radius: 0;
}
  
.right-msg {
    flex-direction: row-reverse;
}

.right-msg .msg-bubble {
    background: #17171b;
    color: #fff;
    border: 1px solid #1c1c21;
    border-bottom-right-radius: 0;
}

.right-msg .msg-img {
    margin: 0 0 0 10px;
}
  
.msger-inputarea {
    padding: 10px;
    border-top: var(--border);
    background: #17171b;
}

.msger-inputarea * {
    padding: 10px 18px;
    border: none;
    border-radius: 5rem;
    font-size: 1em;
}

.msger-input {
    flex: 1;
    background: #1c1c21;
}

.msger-send-btn {
    margin-left: 10px;
    background: rgb(0, 196, 65);
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    transition: background 0.23s;
}

.msger-send-btn:hover {
    background: rgb(0, 180, 50);
}
  
input::placeholder {
    font-size: 12px;
}

.form-control {
    background-color: #17171b;
    color: #ddddde;
    font-size: 12px;
    border: none;
}

/* .fade {
    transition: none;
}

.modal.fade .modal-dialog {
  transition: none;
} */
  
.PhoneInputInput {
    color: #ddd;
    background-color: #1c1c21;
    border: 1px solid #1c1c21;
}

.PhoneInputInput::placeholder {
    color: #acb5bd;
    font-size: 13px;
}
  
.modal-backdrop.show {
    opacity: 0.8;
}

.filepond--root {
    margin-bottom: 2em !important;
    font-size: .7rem !important;
    border: 1px solid #004e93;
}

.filepond--drop-label {
    color: #adb5bd !important;
    background-color: #1c1c21;
}

.filepond--file {
    background: #1c1c21;
    border-radius: 0px;
}

.ai-bg {
    width: fit-content; 
    border-radius: 50px;
    height: 30px;
    width: 30px;
    position: relative;
    left: 6px;
    bottom: 51px;
}

.ai-line-1 {
    border: 2px solid #ffb44a; 
    border-radius: 50px; 
    height: 0rem;
}

.ai-line-2 {
    border: 2px solid #ffb44a; 
    border-radius: 50px; 
    height: 0rem;
}

.ai-line-3 {
    border: 2px solid #ffb44a; 
    border-radius: 50px; 
    height: 0rem;
}

.video-mirror {
    transform: scaleX(-1);
}

.video-bg {
    height: 170px;
    width: 100%;
    object-fit: cover;
}

.loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.loader-container {
    text-align: center;
}

.loader {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.blur {
    filter: blur(4px);
}  

@media (min-width: 576px) {
    .modal-dialog {
        max-width: -webkit-fill-available;
        margin: 1.75rem 1.75rem;
    }
}

.modal-content {
    height: 92vh;
    background-color: #1c1c21;
}

.custom-control {
    padding-left: 2rem;
}

.custom-control-label::before {
    left: -2rem;
    background-color: #1c1c21;
}

.custom-control-label::before {
    border: 1px solid #795548;
}

.p-absolute {
    position: absolute;
}

.w-150 { width: 150%; }

::-webkit-scrollbar { width: 0px; }

.skeleton-loader {
    display: flex;
    flex-direction: column;
  }
  
.skeleton-block {
    width: 100%;
    height: 20px; /* Adjust height as needed */
    background-color: #1c1c21; /* Adjust background color */
    border-radius: 4px; /* Adjust border radius */
    animation: skeleton-pulse 1.5s infinite alternate; /* Animation effect */
}

.skeleton-block-dark {
    width: 100%;
    background-color: #17171b; /* Adjust background color */
    border-radius: 4px; /* Adjust border radius */
    animation: skeleton-pulse 1.5s infinite alternate; /* Animation effect */
}
  
@keyframes skeleton-pulse {
    0% {
        opacity: 0.6; /* Adjust initial opacity */
      }
      50% {
        opacity: 1; /* Peak opacity */
      }
      100% {
        opacity: 0.6; /* Final opacity */
      }
}

.custom-control-label:after, .custom-control-label:before {
    content: "";
    display: block;
    height: 1.25rem;
    left: -2rem;
    position: absolute;
    top: 0.125rem;
    width: 1.25rem;
}


